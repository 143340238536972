import { ISettingsParamsTextKeys } from '../../src/components/BookOnline/settingsParams';
import { SettingsKeys } from '../types';
import { ServiceListSettings } from './appSettings';
import { cleanNulls } from '../utils';

const settingsParamsTextKeyToAppSettingsMapper: {
  [key in ISettingsParamsTextKeys]: SettingsKeys;
} = {
  allServices: SettingsKeys.CATEGORY_ALL_SERVICES_TEXT,
  bookButtonText: SettingsKeys.BOOK_FLOW_ACTION_TEXT,
  viewCourseButtonText: SettingsKeys.VIEW_COURSE_BUTTON_TEXT,
  moreInfoButtonText: SettingsKeys.MORE_INFO_LABEL_TEXT,
  noBookFlowText: SettingsKeys.NO_BOOK_FLOW_ACTION_TEXT,
  onlineBadgeText: SettingsKeys.ONLINE_INDICATION_TEXT,
  pendingApprovalText: SettingsKeys.PENDING_APPROVAL_FLOW_ACTION_TEXT,
  titleText: SettingsKeys.MULTI_OFFERINGS_TITLE_TEXT,
  courseAvailabilityText: SettingsKeys.COURSE_AVAILABILITY_TEXT,
  courseNoAvailabilityText: SettingsKeys.COURSE_NO_AVAILABILITY_TEXT,
};

export function mergeAppSettingsTextsToSettingsParams(
  appSettings: ServiceListSettings,
): { [p: string]: string } {
  const appSettingsText: {
    [key in ISettingsParamsTextKeys]?: string;
  } = {};

  (
    Object.keys(
      settingsParamsTextKeyToAppSettingsMapper,
    ) as ISettingsParamsTextKeys[]
  ).forEach((settingsParamsTextKey) => {
    const appSettingsValue =
      appSettings[
        settingsParamsTextKeyToAppSettingsMapper[settingsParamsTextKey]
      ];
    if (typeof appSettingsValue !== 'undefined') {
      appSettingsText[settingsParamsTextKey] = appSettingsValue as string;
    }
  });

  return cleanNulls(appSettingsText);
}
