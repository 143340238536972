import { WidgetViewModel } from '../../viewModel/viewModel';
import { Resources } from '../../types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ScheduleServer } from '@wix/ambassador-schedule-server/http';
import {
  ListSchedulesRequest,
  ListSchedulesResponse,
} from '@wix/ambassador-schedule-server/types';
import { ServiceType } from '@wix/bookings-uou-types';
import { CoursesAvailability } from './CoursesAvailability';

export type CourseAvailabilityAction = () => void;

interface Params {
  widgetViewModel: WidgetViewModel;
  filteredResources: Resources;
  wixSdkAdapter: WixOOISDKAdapter;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}

export const createCourseAvailabilityAction = ({
  widgetViewModel,
  filteredResources,
  setProps,
  flowAPI,
}: Params) => {
  return async () => {
    const {
      settings,
      experiments,
      controllerConfig: {
        appParams: { instance },
      },
    } = flowAPI;
    const isCourseAvailabilityVisible =
      experiments.enabled('specs.bookings.courseAvailability') &&
      settings.get(settingsParams.isCourseAvailabilityVisible);
    if (isCourseAvailabilityVisible) {
      try {
        const schedulesService = ScheduleServer(
          '/_api/schedule-server/',
        ).Schedules();
        const scheduleOwnerIds = filteredResources.offerings
          .filter((offering) => offering.type === ServiceType.COURSE)
          .map(({ id }) => id);
        const listSchedulesRequest: ListSchedulesRequest = { scheduleOwnerIds };
        const { schedules }: ListSchedulesResponse = await schedulesService({
          authorization: instance,
        }).list(listSchedulesRequest);
        const coursesAvailability: CoursesAvailability = schedules!.reduce(
          (acc, s) => {
            acc[s?.scheduleOwnerId!] = {
              spotsLeft: s?.capacity! - s?.totalNumberOfParticipants!,
            };
            return acc;
          },
          {} as CoursesAvailability,
        );
        setProps({
          widgetViewModel: { ...widgetViewModel, coursesAvailability },
        });
      } catch (e) {
        // TODO: report an error?
      }
    }
  };
};
