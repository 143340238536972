import {
  IWixStyleParams,
  ISettingsFont,
} from '@wix/yoshi-flow-editor/build/cjs/tpa-settings';
import {
  FontProperties,
  SelectedResources,
  SettingsKeys,
  MobileSettingsKeys,
} from '../types';
import {
  AppSettingsColor,
  mergeAppSettingsColorsToStyleParamsColors,
} from './mergeColors';
import {
  mergeAppSettingsNumbersToSettingsParams,
  mergeAppSettingsNumbersToStyleParamsNumbers,
} from './mergeNumbers';
import { mergeAppSettingsNumbersToStyleParamsFonts } from './mergeFonts';
import {
  AlignmentOptions,
  DockLocationOptions,
  FilterLayoutOptions,
  ImagePositionOptions,
  ImageResizeOptions,
  ImageShapeOptions,
  ServiceListLayoutOptions,
} from '../../src/types/types';
import { mergeAppSettingsBooleansToSettingsParams } from './mergeBooleans';
import { mergeAppSettingsTextsToSettingsParams } from './mergeText';
import { mergeAppSettingsCustomsToSettingsParams } from './mergeCustom';

interface AppSettingsFont {
  displayName: string;
  family: string;
  htmlTag: string;
  preset: string;
  size: number;
  style: {
    bold: boolean;
    italic: boolean;
    value?: boolean;
  };
}

export type ServiceListSettings = {
  [key in SettingsKeys]?:
    | AppSettingsColor
    | { themeName: string }
    | AppSettingsFont
    | FontProperties
    | ISettingsFont
    | number
    | boolean
    | string
    | string[]
    | SelectedResources
    | FilterLayoutOptions
    | AlignmentOptions
    | ServiceListLayoutOptions
    | ImagePositionOptions
    | ImageShapeOptions
    | ImageResizeOptions
    | DockLocationOptions;
} & {
  [key in MobileSettingsKeys]?:
    | AppSettingsColor
    | { themeName: string }
    | AppSettingsFont
    | FontProperties
    | number
    | boolean
    | string
    | string[];
};

export const mergeAppSettingsToStyleParams = (
  appSettings: ServiceListSettings,
  stylesParams: IWixStyleParams,
  presetId: string,
) => {
  const colors = mergeAppSettingsColorsToStyleParamsColors(
    appSettings,
    stylesParams.colors,
  );
  const numbers = mergeAppSettingsNumbersToStyleParamsNumbers(
    appSettings,
    stylesParams.numbers,
  );

  const fonts = mergeAppSettingsNumbersToStyleParamsFonts(
    appSettings,
    stylesParams.fonts,
  );

  return {
    colors,
    numbers: { ...numbers, presetId },
    fonts,
  };
};

export const mergeAppSettingsToSettingsParams = (
  appSettings: ServiceListSettings,
  publicData: { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } },
  presetId: string,
): { APP: { [p: string]: any }; COMPONENT: { [p: string]: any } } => {
  const COMPONENT = {
    ...publicData.COMPONENT,
    ...mergeAppSettingsBooleansToSettingsParams(appSettings),
    ...mergeAppSettingsNumbersToSettingsParams(appSettings),
    ...mergeAppSettingsTextsToSettingsParams(appSettings),
    ...mergeAppSettingsCustomsToSettingsParams(appSettings),
    presetId,
  };
  return { APP: publicData.APP, COMPONENT };
};
